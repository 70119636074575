import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { setGlobalContainer } from "react-laag";
import ReactHeap from "./heap";
import reportWebVitals from "./reportWebVitals";
import Application from "components-for/application/application";
import "./index.scss";

import chameleon from "@chamaeleonidae/chmln";

setGlobalContainer("stacking-layers");

if (process.env.NODE_ENV !== "development" && process.env.NODE_ENV !== "test") {
    // Initialise Heap.io
    ReactHeap.initialize(process.env.REACT_APP_HEAP_KEY);

    // Initialise chameleon.io
    chameleon.init(process.env.REACT_APP_CHAMELEON_KEY!, {
        fastUrl: "https://fast.chameleon.io/",
    });
}

if (process.env.NODE_ENV !== "development") {
    Sentry.init({
        dsn: "https://661cbf619e6f43c58ab5e6919bb95900@o1008192.ingest.sentry.io/5972304",
        environment: process.env.NODE_ENV,
        integrations: [new Integrations.BrowserTracing()],
        release: `watchtower@${process.env.REACT_APP_VERSION}`,
        tracesSampleRate: 1.0,
    });
}

ReactDOM.render(<Application />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
